module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blogue Étrangère","short_name":"Étrangère","start_url":"/","background_color":"#FAF6F2","theme_color":"#EEB49E","display":"standalone","icon":"src/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a96ffc937b8688c46cdc6e40f7c48f88"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.etrangere.ca"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"blog-etrangere","accessToken":"MC5Ya3Z5V3hNQUFDSUEwZ3N1.77-9LHZB77-977-977-977-9Se-_vRPvv73vv701Wx13TO-_vRVC77-977-977-977-977-977-9Pe-_vXBf77-9","schemas":{"post":{"Main":{"uid":{"type":"UID","config":{"label":"URL"}},"category":{"type":"Link","config":{"select":"document","customtypes":["post_category"],"label":"Catégorie"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Titre"}},"excerpt":{"type":"StructuredText","config":{"single":"paragraph","label":"Extrait"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Article"}},"image":{"type":"Image","config":{"constraint":{"width":1440,"height":900},"thumbnails":[{"name":"Mobile","width":768,"height":480}],"label":"Image"}}}},"post_category":{"Main":{"name":{"type":"Text","config":{"label":"Nom"}}}}}},
    }]
